.site-footer {
	img {
		width:auto;
		max-height:162px;
	}
	.footer-main {
		i,svg {
			min-width: 38px;
			min-height: 38px;
			color: $brand-primary;
			border: 1px solid $brand-primary;
			padding: 9px;
			font-size: 38px;
		}
	  .footer-bbb {
			min-width: 39px;
			min-height: 40px;
			color: $brand-primary;
			border: 1px solid $brand-primary;
			text-align: center;
			display: inline-block;
			vertical-align: bottom;
			img {
			  	padding: 5px;
					max-height: 39px;
			}
		}
	}
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			padding-bottom:7.5px;
			&:last-of-type {
				padding-bottom:0;
			}
			a {
				color: $brand-primary;
				text-decoration: none;
				font-weight: normal;
			}
		}
	}
	.footer-icon-content {
		color: $brand-primary;
		font-size: 20px;
		font-weight: normal;
		a {
			font-size: 20px;
			color: $brand-primary;
		}
	}
	.gform_wrapper {
		.gform_description {
			margin-bottom:1rem;
			display:block;
		}
		.gfield_required_text {
			color:white;
		}
	}
	.gform_wrapper.gravity-theme .gform_footer button, .gform_wrapper.gravity-theme .gform_footer input, .gform_wrapper.gravity-theme .gform_page_footer button, .gform_wrapper.gravity-theme .gform_page_footer input {
		background:white;
		border:4px solid white;
		color:$brand-primary;
		&:hover {
			background:lighten($brand-primary,15%);
			border:4px solid lighten($brand-primary,15%);
			color:white;
		}
	}
	img.footer-badge {
		max-height: 52px!important;
	}
}

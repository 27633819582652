.single-post {
  .blog-post-content {
    .blog-featured-image {
      margin-bottom:1rem;
      border-radius:6px;
      @include media-breakpoint-up(lg) {
        float:right;
        max-width:45%;
        margin-left:1rem;
      }
    }
  }
}

.single {
  #splide01-track {
    margin: 0!important;
  }
  .range-splider .splide__slider .rpb-slider-arrows .splide__arrow {
    background: transparent;
    box-shadow: none!important;
    opacity: 1;
    margin: 0 10px;
    i, svg {
      color:#fff;
    }
  }
  .featured-project-additional-content {
    ul {
      list-style-type: none;
      li {
        margin: 12px 0;
      }
    }
    i, svg {
        color: lighten($accent, 15%);
    }
  }
}

.single-project-types {
  .similar-projects {
    display: none;
  }
}

.date-block {
  width: 100px;
  margin-top: -30px;
  box-shadow: 0px 8px 3px -5px rgba(0, 0, 0, 0.25);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

  &:before {
    content: "";
    height: 15px;
    width: 15px;
    position: absolute;
    background: darken($green, 25%);
    left: -15px;
    top: 0px;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }
  &:after {
    content: "";
    height: 15px;
    width: 15px;
    position: absolute;
    background: darken($green, 25%);
    right: -14px;
    top: 0px;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
  }
}

.circle-link {
  border-radius: 100%;
  display: inline-flex!important;
  justify-content: center;
  align-items: center;
  border: double 5px;
  height: 40px;
  width: 40px;
  opacity: .5;
}

.blog-page-postcontent {
  &:hover {
    cursor:pointer;
  }
}

.home {
	.page-image-slider {
    display: none;
  }
  .rpb-customer-reviews {
    display: none;
  }
  .page-featured-services {
    display: none;
  }
}

.rpb-hero {
		@media only screen and (max-width: 430px) {
			.btn-accent {
				margin-bottom: 1.25rem;
			}
		}
}

.home-featured-projects {
	.featured-project {
		overflow: hidden;
		.featured-project-content {
			position: relative;
			&:after {
				content:"";
				background: rgba(211, 186, 118, 0.71);
				position: absolute;
				z-index: 0;
				height: 40px;
				width: 40px;
				right: 15px;
				bottom: 15px;
				transition: all 0.25s;
			}
		}
		&:hover {
			.featured-project-content  {
				 transition: all ease-in-out 3s;
				 background-size: 110%!important;
				 position: relative;
				 &:after {
					 height: 100%;
				   width: 100%;
					 right: 0;
					 bottom: 0;
				 }
			}
      .featured-post-title {
        opacity: 1;
      }
		}
    .featured-post-title {
			position: absolute;
			left: 15px;
			bottom: 22px;
			display: block;
			height: 20px;
			top: auto;
			width: 75%;
      transition: all 0.25s;
      opacity: 1;
			font-size: 15px!important;
			margin-bottom: 0;
			text-shadow: 0 1px 3px rgba(0,0,0,0.75);
			z-index: 10;
			@include media-breakpoint-up(lg) {
				opacity: 0;
			}
    }
	}
	.read-more-icon {
		position: absolute;
		right: 15px;
		bottom: 15px;
		left: auto;
		top: auto;
		height: 40px;
		width: 40px;
		padding: 9px 11px;
		transition: all 0.25s;
		z-index: 10;
			i, svg {
				color: #fff;
				font-size: 22px;
			}
			&:hover {
			  background: rgba(211, 186, 118, 0.95);
		}
	}
}

.home-project-type-slider {
	position: relative;
	 #splide01-track {
		 margin: 0!important;
	 }
	 .range-splider .splide__slider .rpb-slider-arrows .splide__arrow {
		 background: transparent;
		 box-shadow: none!important;
		 opacity: 1;
		 margin: 0 10px;
	 }
 .rpb-cards {
	 .card-img, .card-img-top {
		 position: relative;
			border-radius: 0;
			min-height: 250px;
			@include media-breakpoint-up(md) {
				position: absolute;
				top: 0;
				left: 0;
			}
			&:after {
				content:'';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				background: transparent linear-gradient(180deg, #00000000 0%, #12051074 100%);
				z-index: 10;
			}
	 }
	 .card-icon-wrap {
			position: absolute;
			top: 1.25rem;
			right: 1.25rem;
			font-size: 62px;
	 }
	 .card-header, .card-body, .card-footer {
			 position: relative;
			 z-index: 100;
	 }
	 .card-footer {
		 font-size: 20px;
		 font-style: italic;
		 margin: -40px 0 0 0;
		 border: none;
		 background: transparent;
		 @include media-breakpoint-down(lg) {
			 position: absolute;
		 	 top: 36%;
		 	 right: 0%;
		 }
	 }
 }
}

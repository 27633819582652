// Theme Colors/Branding
$brand-primary: #3A4A75;
$brand-primary-rgb: 20,89,186;
$brand-primary-offset: #1459BA;
$brand-secondary: #95A7AA;
$dark: #3E4147;
$light: #95A7AA;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: #C7A64F;
$accent-2: #1459BA;
$body-font: #222;
$error: #d81e00;

// Main Content
.page-container {
  .page-main {
    background:$light;
    padding:25px;
    padding-top:60px;
    padding-bottom:60px;
    line-height:1.9;
  }
}

.rpb-customer-reviews {
  .location-single-review {
    box-shadow: none!important;
    border: 4px solid #efefef;
    .location-single-review-icon {
        background: $accent;
        height: 76px;
        width: 75px;
        opacity: 1 !important;
        padding: 19px 16px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto 1rem;
        top: 18px;
        i,svg {
          font-size: 40px;
          color: #fff;
        }
      }
    }
}

.services {
  .page-services {
    .card {
      .card-img-top {
        min-height: 250px;
      }
    }
    .custom-card {
      .card-img-top {
        @include media-breakpoint-up(lg) {
          min-height: 350px!important;
       }
      }
    }
  }
}

.value-row {
  @include media-breakpoint-up(lg) {
  .the-value {
    strong {
      opacity: 0;
      transition: all 0.25s;
    }
    &:hover {
      strong {
        opacity: 1;
      }
    }
  }
 }
}

.page-project-types {
  .project-icon-wrap {
    position: absolute;
    top: 1.75rem;
    right: 1.75rem;
    left: auto;
    text-align: right;
  }
}

.portfolio, .project-types, .contact, .meet-the-team, .careers, .single, .schedule-appointment {
  .page-image-slider {
    display: none;
  }
  .rpb-customer-reviews {
    display: none;
  }
  .page-featured-services {
    display: none;
  }
}

.meet-the-team {
  .team-member-content {
    margin-top: 1rem;
  }

}

.about-us, .rick-saba, .dustin-linquist {
  .page-image-slider {
    display: none;
  }
  .rpb-customer-reviews {
    display: none;
  }
}

.career-quotes, .question-slider {
  border-top: 5px solid $accent;
  border-bottom: 5px solid $accent;
  .rpb-slider-arrows .splide__arrow {
    background: $accent!important;
  }
}

.page-portfolio {
	.featured-project {
		overflow: hidden;
		&:hover {
			.featured-project-content  {
				 transition: all ease-in-out 3s;
				 background-size: 110%!important;
         .hover-bg {
             @include media-breakpoint-up(lg) {
               right: 0;
               bottom: 0;
               background: rgba(211, 186, 118, 0.8);
               height: 100%;
               width: 100%;
            }
         }
			}
      .featured-post-title {
        opacity: 1;
      }
      span.view-project {
        @include media-breakpoint-up(lg) {
          opacity: 1;
        }
      }
		}
    .featured-post-title {
      position: absolute;
      left: 15px;
      bottom: 22px;
      display: block;
      height: 20px;
      top: auto;
      width: 75%;
      transition: all 0.25s;
      opacity: 1;
      margin-bottom: 0;
      font-size: 18px!important;
      z-index: 50;
      font-style: normal;
      @include media-breakpoint-up(lg) {
        opacity: 0;
        position: absolute;
        left: auto;
        right: auto;
        width: 100%;
        top: 44%;
        display: block;
        height: 24px;
        text-align: center;
        font-size: 24px!important;
      }
    }
    .featured-project-content {
      position: relative;
        .hover-bg {
          position: absolute;
          right: 16px;
          bottom: 16px;
          background: rgba(211, 186, 118, 0.71);
          height: 40px;
          width: 40px;
          left: auto;
          top: auto;
          z-index: 1;
          transition: all 0.25s;
        }
        &:after {
          content:"";
          background: rgba(211, 186, 118, 0.71);
          position: absolute;
          z-index: 0;
          height: 40px;
          width: 40px;
          right: 15px;
          bottom: 15px;
          transition: all 0.25s;
        }
        &:hover:after {
          background: rgba(211, 186, 118, 0.85);
          position: absolute;
          z-index: 0;
          height: 100%;
          width: 100%;
          right: 0;
          bottom: 0;
        }
      }
	 }
	.read-more-icon {
		position: absolute;
		right: 15px;
		bottom: 15px;
		height: 40px;
		width: 40px;
		left: auto;
		top: auto;
		padding: 9px 11px;
    transition: all 0.25s;
    z-index: 50;
			i, svg {
				color: #fff;
				font-size: 22px;
			}
      span.view-project {
        position: absolute;
        right: 40px;
        width: 110px;
        font-weight: 400;
        opacity: 0;
        transition: all 0.25s;
      }
      &:hover {
        background: rgba(211, 186, 118, 0.95);
    }
	}
  .nav-tabs {
    li.nav-item {
      .nav-link {
        position: relative;
        &:before {
          content: '';
          display: block;
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 0;
          height: 1px;
          background:$accent;
          opacity: 0;
          z-index: 1;
          transition: all 0.25s;
        }
        &:hover:before {
          opacity: 1;
          width: 100%;
        }
      }
      .nav-link.active {
        font-weight: bold;
        background: transparent;
        border-bottom: 1px solid $accent!important;
        color:$accent!important;
      }
    }
  }
  #thompsonTabs {
    @include media-breakpoint-down(lg) {
      background: #f2f2f2;
      padding: 10px;
      li.nav-item {
        display: block;
        width: 100%;
        .nav-link {
          display: block;
          width: 100%;
          &:before {
            display: none;
          }
        }
      }
      .nav-link.active {
        font-weight: bold;
        background: transparent!important;
        border-bottom: none!important;
      }
    }
  }
}

.featured-project-additional-content {
  ul {
    list-style: none;
    li {
      position: relative;
    }
    li:before {
      content: "+";
      left: -24px;
      top: 0;
      position: absolute;
      color: lighten($accent, 16%);
      font-weight: bold;
      font-size: 32px;
    }
  }
}

.page-image-slider {
  .splide__track {
    margin:0!important;
    }
    .range-splider .splide__slider .rpb-slider-arrows .splide__arrow {
      background: transparent;
      box-shadow: none!important;
      opacity: 1;
      margin: 0 10px;
      i,svg {

      }
    }
  .card {
    border-radius: 0;
     height: 300px!important;
      @include media-breakpoint-up(xl) {
        height: 600px!important;
    }
  }
  .page-cta {
    position: relative;
    bottom: 0;
    left: 0;
    z-index: 100;
    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 3.5rem;
      left: 8%;
    }
  }
}

.team-members {
  .card {
   position:relative;
   transform-style:preserve-3d;
   transition:transform .6s cubic-bezier(.63,-.32,.4,1.39);
   backface-visibility:hidden;
   .card-front {
    transition:.6s;
    perspective:preserve-3d;
    backface-visibility:hidden;
    transform:rotateY(0);
        @include media-breakpoint-up(lg) {
          img {
           -webkit-filter:grayscale(100%);
           filter:grayscale(100%)
        }
      }
    }
    .card-front, .card-back {
      width:100%
    }
    .card-back {
     position:absolute;
     top:0;
     left:0;
     transition:.6s;
     perspective:preserve-3d;
     backface-visibility:hidden;
     transform:rotateY(-180deg);
     transform-style:preserve-3d;
     transition-property:transform;
    }
    @include media-breakpoint-up(lg) {
      &:hover .card-front {
       transform:rotateY(180deg)
      }
      &:hover .card-back {
       transform:rotateY(0)
      }
    }
  }
}

// Sidebar

.page-container {
  .page-sidebar {
    background:darken($light,15%);
    padding-top:60px;
    padding-bottom:60px;

    .sidebar-carrier {
      margin-top:40px;
      text-align:center;
      img {
        max-width:100%;
      }
    }
  }
}
.sidebar-contact {
  .sb-contact-title {
    background:$brand-secondary;
    color:white;
    text-align:center;
    padding:20px 5px;
    font-weight:700;
    font-size:24px;
    font-family:$heading-font;
    text-transform:uppercase;
  }

  .sb-contact-form {
    background: transparent;
    padding:20px;
    background:white;
    border-left:3px solid darken($light,45%);
    border-right:3px solid darken($light,45%);
    border-bottom:3px solid darken($light,45%);
    border-bottom-right-radius:3px;
    border-bottom-left-radius:3px;

    label {
      display:none !important;
    }

    input, textarea {
        font-weight: 700;
        font-size: 16px;
        color: $dark;
        width: 100%;
        background: transparent;
        text-align: left;
        padding: .75em .65em .75em 0;
        border-right: 0;
        border-top: 0;
        border-left: 0;
        border-bottom: 2px solid darken($light,65%);
        margin: 0 0 0 0;
        transition: all 0.25s ease-in-out;
        max-height: 175px;
        margin-bottom:20px;
    }
    .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
        font-size: 16px;
        font-family: inherit;
        padding: .75em .65em .75em 0;
        letter-spacing: normal;
    }
    input[type="submit"] {
      background:$brand-secondary;
      color:white;
      border:none;
      padding:15px 25px;
      border-radius:3px;
      transition-duration:350ms;
      -webkit-transition-duration:350ms;
      -moz-transition-duration:350ms;
      &:hover {
        background:lighten($brand-secondary,5%);
        cursor:pointer;
      }
    }
  }
}

// 404 Page

.error-page {
  background-repeat:no-repeat;
  background-position:center top;
  background-size:cover;
  color:white;
  min-height:750px;
  .error-title {
    font-size:150px;
    font-weight:bold;
    img {
      max-height:175px;
    }
  }
  .error-subtitle {
    font-weight:normal;
    a {
      text-decoration:underline;
      &:hover {
        color:lighten($brand-primary,20%);
      }
    }
  }
}
